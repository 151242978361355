import { computed } from 'vue'
import store from '@/store'
import { get } from 'lodash'

export const useOrg = () => {
  const orgId = computed(() => {
    return get(store, 'state.org.org.id')
  })
  const dashboardConfig = computed(() => {
    return get(store, 'state.org.org.DashboardConfig')
  })
  return { orgId, dashboardConfig }
}
